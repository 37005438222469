import React,{ useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Login } from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import Loader from "./components/Loader";
import AuthCheckProvider from "./components/AuthCheckProvider";
import authService from "../services/authService";
import {useToaster } from "rsuite"

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkLoggedInUser = async() => {
   
    const isAuthenticated = await authService.isAuthenticated();
    setIsLoggedIn(isAuthenticated);
}

useEffect(() => {
    checkLoggedInUser();

},[]);

  return (
    <AuthCheckProvider>
      <HelmetProvider>
          <Routes>
          
            {/* <Route exact path="/" element={<Login/>} /> */}
            <Route exact path="/login" element={<Login/>} loader={Loader}/>
            <Route exact path="/dashboard" element={<Dashboard/>} loader={Loader}/>
            <Route exact path="/" element={!isLoggedIn ? <Login/> : <Dashboard/>} loader={Loader}/>
          </Routes>
          
      </HelmetProvider>
     </AuthCheckProvider>
  );
}

export default App;