import React, { useEffect, useState, useRef, SelectPicker } from "react";
import Header from "../../components/Header";
import { Table, Input, Loader, Pagination, InputGroup } from "rsuite";
import moment from "moment-timezone";
import _ from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import userService from "../../../services/userService";
import EditIcon from "@rsuite/icons/Edit";
import CloseIcon from "@rsuite/icons/Close";
// import TablePagination from "rsuite/lib/Table/TablePagination";

const { Column, Cell, HeaderCell } = Table;

const Dashboard = () => {
  const tableRef = useRef();

  let lastSkip = 0;
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const [patientList, setPatientList] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [totalItem, settotalItem] = useState(0);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [filterValue, setFilterValue] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedUserLimit, setSelectedUserLimit] = useState();
  const [editableRow, setEditableRow] = useState({
    row: null
  });
  const ChartIcon = ({ size }) => (
    <EditIcon style={{ fontSize: size, marginRight: 10 }} />
  );
  // const [layout, setLayout] = React.useState(['totalItem', '-', 'limit', '|', 'pager', 'skip']);

  const getAllUsers = (query, columnName, type) => {
    userService.getUserToken("access").then(accessToken => {
      axios
        .get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }admin/user?currentPage=${page}&pageSize=${limit}&search=${query ??
            ""}&orderBy=${columnName ?? sortColumn}&orderDir=${type ??
            sortType}`,
          {
            headers: {
              Accept: "application/json",
              Platform: "Web",
              Authorization: `Bearer ${accessToken.token}`
            }
          }
        )
        .then(function({ data }) {
          settotalItem(data.data.totalItem);
          if (data.data?.userList) {
            setPatientList(data.data.userList);
          }
        })
        .catch(e => {
          if (e.response?.data?.message) {
            toast(e.response?.data?.message, { type: "error" });
            return;
          }
          toast("Some error occurred while logging in!", { type: "error" });
        });
    });
  };
  const updateUserDailyLimit = (userId, limit) => {
    userService.getUserToken("access").then(accessToken => {
      axios
        .patch(
          `${process.env.REACT_APP_API_BASE_URL}admin/user/${userId}`,
          {
            dailyFetchLimit: limit
          },
          {
            headers: {
              Accept: "application/json",
              Platform: "Web",
              Authorization: `Bearer ${accessToken.token}`
            }
          }
        )
        .then(function({ data }) {
          getAllUsers();
        })
        .catch(e => {
          if (e.response?.data?.message) {
            toast(e.response?.data?.message, { type: "error" });
            return;
          }
          toast("Some error occurred while updating!", { type: "error" });
        });
    });
  };

  const handleChangePage = dataKey => {
    setPage(dataKey);
  };

  const handleChangeLimit = dataKey => {
    console.log("limit =", dataKey);
    setLimit(dataKey);
  };

  useEffect(() => {
    setLoading(true);
    getAllUsers();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [page, limit]);

  const loadMore = () => {
    //   if(loading) return;
    //
    if (patientList.length < totalItem) {
      // if(patientList.length > 0)
      //     setLoading(false)
      // else
      setLoading(true);
      getAllUsers(patientList.length);
    }
  };

  const handleScroll = (x, y) => {
    const contextHeight = patientList.length * 60;
    const top = Math.abs(y);
    const tHeight = getTableHeight();
    // console.log("y==",y," getTableHeight ==", tHeight, " contextHeight =", contextHeight)
    if (
      contextHeight - top - tHeight < tHeight - 200 &&
      !loading &&
      lastSkip !== patientList.length
    ) {
      lastSkip = patientList.length;
      loadMore();
    }
    // if (contextHeight - top - tableHeight < tableHeight-100 && contextHeight - top - tableHeight > tableHeight-150) {
    //     setLoad(true);
    //   loadData();
    // }
  };

  const getTableHeight = () => {
    try {
      const topMessage = document.getElementById("message").offsetHeight;
      const headerHeight = document.getElementById("headerID").offsetHeight;
      const dataListOptions = document.getElementById("dataListOptions")
        .offsetHeight;
      const windowHeight = window.innerHeight;
      return windowHeight - (headerHeight + dataListOptions + topMessage);
    } catch (e) {
      return window.innerHeight;
    }
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setSortType(sortType);
    setSortColumn(sortColumn);
    getAllUsers(filterValue, sortColumn, sortType);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  // useEffect(() => {
  //     seTableHeight(getTableHeight())
  // },[])

  const clearFilter = () => {
    setFilterValue("");
    setLoading(true);
    getAllUsers("", sortColumn, sortType);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const getSubscriptionInfo = info => {
    if (typeof info != "object") {
      return "Free";
    } else if (info?.cancelled) {
      return "Free";
    } else if (info?.cancelled === false) {
      return "Paid";
    }
    return "Free";
  };

  const loadResults = () => {
    setLoading(true);
    getAllUsers(filterValue);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const editData = index => {
    setEditableRow({ row: index });
  };

  const handleKeyDown = (event, rowData, index) => {
    if ((event.key === "Enter" || event.type === "blur") && selectedUserLimit) {
      updateUserDailyLimit(rowData._id, selectedUserLimit);
      setEditableRow({
        row: null
      });
      setSelectedUserLimit();
    }
  };

  const inviteUser = async rowData => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}users/resend-verification-email`,
        {
          email: rowData.email
        },
        {
          headers: {
            Accept: "application/json",
            Platform: "Web"
            // Authorization:`Bearer ${accessToken.token}`,
          }
        }
      );
      toast("Invite mail sent!", { type: "success" });
    } catch (err) {
      //  console.log("error ==", err);
      if(err.response && err.response.data){
        toast(err.response.data.message, { type: "error" });
      }else{
        toast("Some error occurred while sending invite", { type: "error" });
      }
    }
  };

  return (
    <div className="bg-gradient-to-r from-white from-40% via-green-300 via-60% to-white w-full h-screen overflow-scroll">
      <Header active={1} />
      {/* <div className="w-full p-8 flex pl-16">
                <img src="/images/logo.png" className="h-16" />
            </div> */}

      <div className="w-full">
        <div
          style={{ marginBottom: "20px", display: "flex", marginLeft: "50px" }}
        >
          <InputGroup inside style={{ width: "400px" }}>
            <Input
              placeholder="Search in first name, last name, email, NPN"
              value={filterValue}
              onChange={val => setFilterValue(val)}
            />
            <InputGroup.Button onClick={() => clearFilter()}>
              <CloseIcon style={{ fontSize: "12px" }} />
            </InputGroup.Button>
          </InputGroup>
          <button
            style={{
              marginLeft: "15px",
              background: "#F2A64D",
              padding: "5px 10px",
              borderRadius: "5px",
              color: "white",
              fontWeight: "bold",
              fontSize: "13px",
              width: "100px"
            }}
            onClick={() => loadResults()}
          >
            Search
          </button>
        </div>
        <div id="tableContainer" className=" relative">
          <Table
            wordWrap="break-word"
            className="rounded-md bg-white"
            // rowClassName="rs-table-row-custom hover:bg-green-300"
            // bordered={true}
            showHeader={true}
            ref={tableRef}
            affixHorizontalScrollbar
            shouldUpdateScroll={false}
            locale={{ emptyMessage: "" }}
            // autoHeight
            data={patientList}
            rowHeight={60}
            // height={getTableHeight()}
            height={500}
            headerHeight={55}
            // autoHeight
            virtualized
            onScroll={isUserSubscribed ? handleScroll : () => {}}
            // rowClassName={(rowData, index) => (rowData && rowData["marked"]) ? 'read-bg' : ''}
            loading={loading}
            onSortColumn={handleSortColumn}
            sortColumn={sortColumn}
            sortType={sortType}
          >
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              sortable
              resizable
            >
              <HeaderCell>First Name</HeaderCell>
              <Cell dataKey="first_name" style={{ fontSize: "12px" }}>
                {rowData => (rowData.first_name ? rowData.first_name : "")}
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              sortable
              resizable
            >
              <HeaderCell>Last Name</HeaderCell>
              <Cell dataKey="last_name" style={{ fontSize: "12px" }}>
                {rowData => (rowData.last_name ? rowData.last_name : "")}
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              sortable
              resizable
            >
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" style={{ fontSize: "12px" }}>
                {rowData => (rowData.email ? rowData.email : "")}
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              sortable
              resizable
            >
              <HeaderCell>NPN</HeaderCell>
              <Cell dataKey="npn" style={{ fontSize: "12px" }}>
                {rowData => (rowData.npn ? rowData.npn : "")}
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              sortable
              resizable
            >
              <HeaderCell>Phone</HeaderCell>
              <Cell dataKey="phone" style={{ fontSize: "12px" }}>
                {rowData => (rowData.phone ? rowData.phone : "")}
              </Cell>
            </Column>
            <Column
              flexGrow={2}
              verticalAlign="middle"
              align="center"
              sortable
              resizable
            >
              <HeaderCell>TOS Acceptance Timestamp</HeaderCell>
              <Cell dataKey="createdAt" style={{ fontSize: "12px" }}>
                {rowData =>
                  rowData.createdAt
                    ? moment(rowData.createdAt).format("DD/MM/yyyy HH:mm A")
                    : ""
                }
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              resizable
            >
              <HeaderCell>User Type</HeaderCell>
              <Cell dataKey="subscriptionInfo" style={{ fontSize: "12px" }}>
                {rowData =>
                  rowData.subscriptionInfo
                    ? getSubscriptionInfo(rowData.subscriptionInfo)
                    : "Free"
                }
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              resizable
            >
              <HeaderCell>Browser</HeaderCell>
              <Cell dataKey="browserName" style={{ fontSize: "12px" }}>
                {rowData =>
                  rowData.tosLog && rowData.tosLog.browserName
                    ? rowData.tosLog.browserName
                    : ""
                }
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              verticalAlign="middle"
              align="center"
              resizable
            >
              <HeaderCell>IP</HeaderCell>
              <Cell dataKey="publicIP" style={{ fontSize: "12px" }}>
                {rowData =>
                  rowData.tosLog && rowData.tosLog.publicIP
                    ? rowData.tosLog.publicIP
                    : ""
                }
              </Cell>
            </Column>
            <Column
              flexGrow={2}
              verticalAlign="middle"
              align="center"
              resizable
            >
              <HeaderCell>User Agent</HeaderCell>
              <Cell dataKey="userAgent" style={{ fontSize: "12px" }}>
                {rowData =>
                  rowData.tosLog && rowData.tosLog.userAgent
                    ? rowData.tosLog.userAgent
                    : ""
                }
              </Cell>
            </Column>
            <Column
              flexGrow={2}
              verticalAlign="middle"
              align="center"
              resizable
            >
              <HeaderCell>Daily Limit</HeaderCell>
              <Cell
                className="bob"
                dataKey="dailyFetchLimit"
                style={{ fontSize: "12px" }}
              >
                {(rowData, index) =>
                  editableRow.row != index ? (
                    <div className="flex justify-center items-center">
                      <span>{rowData?.dailyFetchLimit ?? 0}</span>
                      {!editableRow.row && (
                        <div class="edit" onClick={() => editData(index)}>
                          <ChartIcon size="1.5em" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <Input
                      defaultValue={rowData?.dailyFetchLimit ?? 0}
                      onKeyDown={e => {
                        handleKeyDown(e, rowData, index);
                      }}
                      onBlur={e => {
                        updateUserDailyLimit(rowData._id, selectedUserLimit);
                        setEditableRow({
                          row: null
                        });
                        setSelectedUserLimit();
                      }}
                      onChange={(value, e) => {
                        console.log(value, e);
                        setSelectedUserLimit(value);
                      }}
                    />
                  )
                }
              </Cell>
            </Column>
            <Column lexGrow={1} verticalAlign="middle" align="center" resizable>
              <HeaderCell>Used Limit</HeaderCell>
              <Cell dataKey="usedLimit" style={{ fontSize: "12px" }}>
                {rowData => (rowData.usedLimit ? rowData.usedLimit : "0")}
              </Cell>
            </Column>
            <Column
              width={120}
              verticalAlign="middle"
              align="center"
              resizable
              fixed="right"
            >
              <HeaderCell></HeaderCell>
              <Cell dataKey="invite" style={{ fontSize: "12px" }}>
                {rowData => (
                  <div
                    onClick={() => inviteUser(rowData)}
                    className="cursor-pointer underline text-blue-500 pr-2"
                  >
                    {"Invite Again"}
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <Pagination
            className="mt-3 px-5"
            layout={["total", "-", "limit", "|", "pager"]}
            limitOptions={[10, 20, 50, 100]}
            size="xs"
            activePage={page}
            limit={limit}
            total={totalItem}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
          {/* {
                    (patientList.length <1) && (
                        <div className="flex justify-center relative -mt-96 z-100">
                            <div>
                            <div className="text-orange-200">No user record available.</div>
                            </div>
                        </div>
                    )
                } */}
          {/* {loading && <FixedLoader />} */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
