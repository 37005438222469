import React, {useState, useEffect} from 'react';
import authService from "../../../services/authService";
import userService from "../../../services/userService";
import { delayedRedirect } from '../../../utils/response';
import { toast } from 'react-toastify';
import localforage from "localforage";
import { request } from '../../../utils/request';

const Header = (props) => {
    
    // const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [refreshToken, setrefreshToken] = useState()

    const checkLoggedInUser = async() => {
        const refreshToken = await userService.getUserToken('refresh');
        // console.log("refreshToken ==", refreshToken)
        if(refreshToken) setrefreshToken(refreshToken.token)
        const isAuthenticated = await authService.isAuthenticated();
        setIsLoggedIn(isAuthenticated);
    }

    useEffect(() => {
        checkLoggedInUser();

    },[]);

    const onLogout = () => {
            request(`${process.env.REACT_APP_API_BASE_URL}auth/logout`, {
                method: 'POST',
                data:{"refreshToken":refreshToken}
        })
        .then(function () {
            toast('Logged out successfully!',{type:"success"});
            userService.removeUserToken('access');
            userService.removeUserToken('refresh');
            userService.removeUser();
            localforage.clear();
            localStorage.clear()
            delayedRedirect('/login', 500)
        }).catch((e)=>{
        if (e.response?.data?.message) {
        toast(e.response?.data?.message,{type:"error"});
        return;
        }
            toast('Some error occurred!',{type:"error"});
        })
    }

    return(
        <div id="headerID" className="w-full pt-4 lg:p-8 md:p-4 md:flex lg:flex">
            <div className="pl-8 w-fit">
                <a href='/'><img alt="" src="/images/full-logo.png" className="h-8 md:h-10 lg:h-16 object-contain" /></a>
            </div>
           <div className="flex flex-wrap mt-3 gap-y-2 w-full justify-end my-5 md:my-0 lg:mt-0 px-5 md:px-20 lg:px-20 items-center">
            {/* {
                isLoggedIn && (
                    <div className="flex gap-x-5 items-center">
                        <div onClick={onAccount}  className={`cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-5 py-2 text-xs ${active === 0 ? 'bg-orange-200' : 'bg-orange-100'} text-white rounded-md h-8`}>
                            <img className="h-4" src="/images/user-icon-logged.png"  />
                            Account
                            </div>
                        <div onClick={onMighty} className={`cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-5 py-2 text-xs ${(active == 1) ? 'bg-orange-200' : 'bg-orange-100'} text-white rounded-md h-8`}>
                            <img className="h-4" src="/images/mighty.png"  />
                            Dashboard
                        </div>
                    </div>
                )
            } */}
             {
                (isLoggedIn) && (
                    <div onClick={() => onLogout()} className="shadow-lg font-bold cursor-pointer px-5 py-2 text-xs bg-green-200 text-white rounded-md h-8">{`> Logout`}</div>
                )
            }
           {/* {
                (!isLoggedIn) && (
                    <div className="flex items-center gap-x-2 shadow-lg font-bold px-6 py-2 text-xs bg-orange-200 text-white rounded-md h-8">
                        <img className="h-4" src="/images/account_white.png"  />
                        Account
                    </div>
                )
            } */}
           </div>
            
        </div>
        
    )
}

export default Header;