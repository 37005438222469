import React, {  useState } from "react";
import {  Field, Form, Formik } from "formik";
import  Header  from "../../components/Header";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import userService from '../../../services/userService';
import { delayedRedirect, redirect } from '../../../utils/response';

export const Login = () => {

    const SigninSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email Required"),
        password: Yup.string()
          .required("No password provided.")
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain one uppercase, one number and one special case character."),
      });

    // const dispatch = useDispatch()
    // const [user, setUser] = useState({email:'', password:''});
    const [showPassword, setShowPassword] = useState(false)

    const saveData = (values) => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}auth/admin-login`, {
            "email":values.email,
            password:values.password,
        })
        .then(function ({data}) {
            toast('You are successfully logged in',{type:"success"});
            userService.saveUserToken(data.tokens.access, 'access')
            userService.saveUserToken(data.tokens.refresh, 'refresh')
            userService.saveUser(data.user)
            delayedRedirect('/dashboard', 500)
        }).catch((e)=>{
            if (e.response?.data?.message) {
            toast(e.response?.data?.message,{type:"error"});
            return;
            }
            toast('Some error occurred while logging in!',{type:"error"});
        })
    }

    return(
        <div className="pb-10 bg-green-100 w-full h-screen overflow-y-scroll overflow-x-hidden">
            <Header/>
            {/* <div className="w-full p-8 flex pl-16">
                <img src="/images/logo.png" className="h-16" />
            </div> */}
            <div className="flex flex-wrap mt-0 w-full bg-green-100 h-full">
                <div className="w-full md:w-1/2 lg:w-1/2 z-[100] mt-10 p-4 pb-16 md:pl-16 lg:pl-16 md:p-0 lg:p-0">
                <Formik
                     validationSchema={SigninSchema}
                    initialValues = {{email:'', password:''}}
                    onSubmit={(values) => {
                    saveData(values);
                }}
                >
                {
                    ({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        setValues,
                    }) => (
                        <Form>
                            <div className="bg-white rounded-2xl p-5 w-full px-10 py-10">
                                <h1 className="font-bold text-xl text-gray-600">Login</h1>
                                <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                                <p className="py-3 text-gray-500 text-lg">Login to MediZues admin.</p>

                                
                                    <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-5 ">
                                        <p className="text-gray-500 text-md font-bold">Username</p>
                                        <Field
                                            className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                            type="text"
                                            name="email"
                                            value={values?.email}
                                            required
                                            placeholder=""
                                        />
                                        {errors.email && touched.email ? (
                                            <p className="mt-2 text-red-500 text-xs">{errors.email}</p>
                                        ) : null}
                                    </div>
                                    <div className="mt-2 rounded-2xl border-2 border-green-100 p-3 text-center my-5 ">
                                        <p className="text-gray-500 text-md font-bold">Password</p>
                                        <div className="flex items-center ">
                                        <Field
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            value={values?.password}
                                            required
                                            placeholder=""
                                            className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                        />
                                            <img alt="" onClick={() => setShowPassword(!showPassword)} className="cursor-pointer h-6 -ml-10 mt-1" src="/images/eye_grey.png"  />
                                        </div>
                                        {errors.password && touched.password ? (
                                            <p className="mt-2 text-red-500 text-xs">{errors.password}</p>
                                        ) : null}
                                    </div>
                            
                                <div className="text-gray-400 flex flex-wrap gap-x-5 items-center mt-10 w-full justify-between">
                                    <button type="submit" className="font-bold text-white bg-green-200 py-1 px-8 rounded-md mt-5 md:mt-0 lg:mt-0">{`Login >`}</button>
                                </div>
                                {/* <div onClick={() => loginG()} className="flex items-center text-gray-400 font-bold border border-gray-50 bg-white shadow-lg w-full px-3 py-1 mt-10 rounded-md ">
                                    <img alt="" className="h-10" src="/images/google-button.png"  /> <div className="flex w-full justify-center"><p>Or continue with Google</p></div>
                                </div> */}
                            </div>
                        </Form>
                    )
                }
                </Formik>
               
               
                </div>
                <div className="hidden md:block lg:block w-full md:w-1/2 lg:w-1/2 mt-10">
                    {/* <img src="/images/splash.png"  /> */}
                    <div className="z-0 w-full flex justify-between relative">
                        <img alt="" src="/images/cloud_1.png" className="z-0 w-2/6 h-42 object-contain absolute pt-52" />
                        <img alt="" src="/images/zues.png"  className="w-4/6 h-80 object-contain pt-20"/>
                        <img alt="" src="/images/cloud_2.png" className="z-0 w-2/6 h-42 object-contain pt-96" />
                    </div>
                    <div className="lg:-mt-52 md:-mt-44 z-[100] relative">
                        {/* <h1 className=" text-center text-orange-200 font-bold text-4xl">MediZues</h1> */}
                        <div className="w-full flex justify-center">
                            <img alt="" src="/images/medizues-text.png" className="h-24 w-60 object-contain item-center" />
                        </div>
                        <h1 className="text-center text-green-200 font-bold text-lg mt-2">Get god-like insights on your clients behavior</h1>
                        {/* <h1 className=" text-center text-orange-200 font-bold text-4xl">The mighty<br/> ZuesRx extension</h1>
                        <h1 className="text-center text-green-200 font-bold text-lg mt-2">simply makes the most of your sheets!</h1> */}
                    </div>
                    
                </div>
            </div>
            
        </div>
    )
}